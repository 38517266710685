/*
 * Essential Imports
 */

import 'picturefill';
import 'lazysizes';
import './vendor/ls.object-fit';

/*
 * Essentail Fixes
 */

// Preload and No-JS
setTimeout(function () {
  document.documentElement.classList.remove('preload');
  document.documentElement.classList.remove('no-js');
}, 300);

let resizeId;
window.addEventListener('resize', function () {
  clearTimeout(resizeId);
  document.documentElement.classList.add('preload');
  resizeId = setTimeout(function () {
    document.documentElement.classList.remove('preload');
  }, 300);
});

// Fix NodeList.forEach in IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// StickyBits
// import stickybits from 'stickybits';

// Accordion
import Accordion from './functions/accordion';
const accordion = new Accordion();

// ModalWindow
import ModalWindow from './functions/modal-window';
const modal_window = new ModalWindow();

// Panel
import Panel from './functions/panel';
const panel = new Panel();

// Swiper
import Swiper from './vendor/swiper';
document.querySelectorAll('.js-swiper').forEach(swiper => {
  let swiperID = swiper.id;
  let params = swiper.dataset.swiper;
  let mySwiper = new Swiper(swiper, JSON.parse(params));

  // Swiper Height
  document.addEventListener('lazyloaded', function (e) {
    if (swiper.dataset.autoheight) mySwiper.updateAutoHeight();
  });

  // Swiper Pause
  let elapsed = 0;
  let st = new Stopwatch();
  let pauseBtn = document.getElementById(swiperID + '_pause');

  st.start();

  if (typeof pauseBtn != 'undefined' && pauseBtn != null) {
    pauseBtn.addEventListener('click', event => {
      if (pauseBtn.parentElement.classList.contains('is-paused')) {
        pauseBtn.parentElement.classList.remove('is-paused');

        setTimeout(function () {
          mySwiper.slideNext();
          mySwiper.autoplay.start();
          st.start();
        }, 5000 - elapsed);
      } else {
        pauseBtn.parentElement.classList.add('is-paused');
        mySwiper.autoplay.stop();

        st.stop();
        elapsed = st.getElapsed();
        st.clear();
      }
    });

    mySwiper.on('slideChangeTransitionStart', function () {
      st.clear();
      st.start();
    });
  }
});

// Swiper GOTO
document.querySelectorAll('.js-swiper_goto').forEach(goto => {
  goto.addEventListener('click', event => {
    event.currentTarget.blur();

    let swiperSlider = JSON.parse(goto.dataset.slider);
    let swiperSlide = goto.dataset.slide;

    for (var i = 0; i < swiperSlider.length; i++) {
      let mySwiper = document.getElementById(swiperSlider[i]).swiper;
      mySwiper.slideTo(swiperSlide);
    }
  });
});

// Swiper Pagination
jQuery('.swiper-pagination-bullet', '.tours').on('click', function () {
  let tours = jQuery(this).parents('.tours');
  let headerHeight = document.querySelector('.header').offsetHeight;
  let stickyHeight = 0;

  if (
    typeof document.querySelector('.js-sticky') != 'undefined' &&
    document.querySelector('.js-sticky') != null
  )
    stickyHeight = document.querySelector('.js-sticky').offsetHeight;

  let y = tours.offset().top - (headerHeight + stickyHeight);

  window.scrollTo({
    top: y,
    behavior: 'smooth',
  });
});

/*
document.querySelectorAll('.swiper-pagination-bullet').forEach(goto => {
    goto.addEventListener('click', event => {
        console.log(1);
    });
});
*/

function Stopwatch() {
  var startTime,
    endTime = this;

  this.start = function () {
    startTime = new Date();
  };

  this.stop = function () {
    endTime = new Date();
  };

  this.clear = function () {
    startTime = new Date();
    endTime = new Date();
  };

  this.getElapsed = function () {
    if (!endTime) {
      return 0;
    }
    return endTime.getTime() - startTime.getTime();
  };
}

// FormValidation
import './functions/form-validation';

const recaptchaSiteKey = document.querySelector("meta[name='recaptchaSiteKey']")
  ? document
      .querySelector("meta[name='recaptchaSiteKey']")
      .getAttribute('content')
  : false;

document.querySelectorAll('form.js-validate').forEach(form => {
  const formUsesRecaptcha = !!form.querySelector('.g-recaptcha');

  new Validate(form, {
    recaptchaSiteKey: formUsesRecaptcha ? recaptchaSiteKey : false,
    fieldFeedbackSelector: '.js-field-feedback span',
  });
});

// In-field Labels
document.querySelectorAll('.js-infield').forEach(field => {
  field.addEventListener('keyup', event => {
    field.setAttribute('value', field.value);
  });
});

// Invalid Modal Forms
jQuery('.js-modal_wrapper').each(function () {
  let modal = jQuery(this);
  let modalID = modal.attr('id');
  if (modal.find('.is-invalid').length > 0) {
    jQuery(
      '.js-modal_trigger[data-modal="' + modalID + '"]:not(.js-modal_close)'
    ).trigger('click');
  }
});

// Form Submission Spinner
var form = document.getElementsByTagName('form');
var forms = Array.prototype.slice.call(form);
forms.forEach(form => {
  var submit_btn = form.querySelector('button[type=submit]');
  if (submit_btn != 'undefined' && submit_btn != null) {
    submit_btn.classList.add('has_ajax-loader');

    form.addEventListener('submit', function () {
      submit_btn.classList.add('is_ajax-loading');

      var fields_invalid = form.querySelectorAll('.is-invalid');

      if (fields_invalid.length > 0)
        submit_btn.classList.remove('is_ajax-loading');
    });
  }
});

// Gravity Forms
let gfCheckboxes = [...document.querySelectorAll('.gfield_checkbox li')];

if (gfCheckboxes.length) {
  gfCheckboxes.forEach(checkbox => {
    let span = document.createElement('span');
    span.classList.add('gf-checkbox-span');
    span.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><polygon points="12.4,2.1 5.7,8.8 1.5,4.7 0,6.2 5.7,11.9 14,3.6"/></svg>';
    checkbox.append(span);
  });
}

let gfRadios = [...document.querySelectorAll('.gfield_radio li')];

if (gfRadios.length) {
  gfRadios.forEach(radio => {
    let span = document.createElement('span');
    span.classList.add('gf-radio-span');
    radio.append(span);
  });
}

let gfConsent = [...document.querySelectorAll('.ginput_container_consent')];

if (gfConsent.length) {
  gfConsent.forEach(checkbox => {
    let span = document.createElement('span');
    span.classList.add('gf-checkbox-span');
    span.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><polygon points="12.4,2.1 5.7,8.8 1.5,4.7 0,6.2 5.7,11.9 14,3.6"/></svg>';
    checkbox.querySelector('label').after(span);
  });
}

// FlashMessage
import { FlashMessage } from './functions/flash-message';
new FlashMessage();

// Cookies
import Cookies from 'js-cookie';
let cookieNotice = document.getElementById('cookie-notice');
if (
  cookieNotice != 'undefined' &&
  cookieNotice != null &&
  Cookies.get('cookie-notice') !== 'true'
) {
  document.querySelectorAll('.js-cookie_close').forEach(el => {
    el.addEventListener(
      'click',
      event => {
        event.preventDefault();
        Cookies.set('cookie-notice', 'true', { expires: 7 });
        cookieNotice.classList.add('hide');
      },
      false
    );
  });
}

// secondary nav links
var secondaryNavLinks = document.querySelectorAll('.secondary-nav-links');
var activeSecondNavEle, disableScrollActiveEle, disableScrollEleTimeout;

function isInViewport(element) {
  var position = element.getBoundingClientRect();
  // checking for partial visibility

  let botDif;
  if (window.innerWidth >= 768) botDif = 205;
  else botDif = 76;

  if (position.top < window.innerHeight && position.bottom >= botDif)
    return true;
  else return false;
}

function navLinkClickEvent(e) {
  let targetInd = e.target.getAttribute('index');
  for (let i = 0; i < secondaryNavLinks.length; i++) {
    if (i != targetInd) secondaryNavLinks[i].classList.remove('active');
    else
      secondaryNavLinks[i].classList.add('active'),
        (activeSecondNavEle = secondaryNavLinks[i]);
  }

  // Toggle mobile active class
  if (window.innerWidth < 768)
    document.getElementById('secondary-nav__nav').classList.toggle('active');

  // Add cooldown to scroll update active section
  if (disableScrollEleTimeout) clearTimeout(disableScrollEleTimeout);
  disableScrollActiveEle = true;
  disableScrollEleTimeout = setTimeout(() => {
    disableScrollActiveEle = false;
  }, 500);
}

function secondaryNavToggle() {
  document.getElementById('secondary-nav__nav').classList.toggle('active');
}

if (secondaryNavLinks.length) {
  for (let i = 0; i < secondaryNavLinks.length; i++) {
    secondaryNavLinks[i].addEventListener('click', navLinkClickEvent, {
      passive: true,
    });
  }
  // Add event to toggle dropdown
  let secondaryNavToggles = document.querySelectorAll(
    '.toggle-mobile-secondary-nav-dropdown'
  );
  for (let n = 0; n < secondaryNavToggles.length; n++) {
    secondaryNavToggles[n].addEventListener('click', secondaryNavToggle, {
      passive: true,
    });
  }
}

// Sticky Header
if (window.pageYOffset > 0)
  document.querySelector('body').classList.add('is-sticky-header');

window.addEventListener('scroll', function () {
  if (window.pageYOffset > 0)
    document.querySelector('body').classList.add('is-sticky-header');
  else document.querySelector('body').classList.remove('is-sticky-header');

  // Check what secondary nav links are in viewport
  if (secondaryNavLinks.length && !disableScrollActiveEle) {
    for (let i = 0; i < secondaryNavLinks.length; i++) {
      let id = secondaryNavLinks[i].getAttribute('href');
      let ele = document.querySelector(id);
      if (isInViewport(ele)) {
        for (let n = 0; n < secondaryNavLinks.length; n++) {
          secondaryNavLinks[n].classList.remove('active');
        }
        activeSecondNavEle = secondaryNavLinks[i];
        activeSecondNavEle.classList.add('active');
        break;
      }
    }
  }
});

// Submenu
document.querySelectorAll('.js-submenu_toggle').forEach(elem => {
  elem.addEventListener(
    'click',
    function (el) {
      el.preventDefault();
      el.stopPropagation();
      el.currentTarget.blur();

      if (el.currentTarget.nextElementSibling.classList.contains('open')) {
        el.currentTarget.nextElementSibling.classList.remove('open');
      } else {
        document.querySelectorAll('.subnav-primary.open').forEach(submenu => {
          if (submenu != el) submenu.classList.remove('open');
        });

        el.currentTarget.nextElementSibling.classList.add('open');

        if (!jQuery('.subnav-primary.open').hasClass('is-fixed')) {
          var offsetRight =
            jQuery(window).width() -
            (jQuery('.subnav-primary.open').offset().left +
              jQuery('.subnav-primary.open').outerWidth());
          if (offsetRight < 0) {
            jQuery('.subnav-primary.open').addClass('is-fixed');
          } else {
            jQuery('.subnav-primary.open').removeClass('is-fixed');
          }
        }
      }
    },
    false
  );
});

document.addEventListener(
  'click',
  function (el) {
    document.querySelectorAll('.subnav-primary.open').forEach(elem => {
      if (
        elem != el.target &&
        elem.contains(el.target) == false &&
        elem.classList.contains('open')
      ) {
        elem.classList.remove('open');
      }
    });
  },
  false
);

window.addEventListener('resize', function () {
  if (jQuery('.subnav-primary').hasClass('open')) {
    jQuery('.subnav-primary.open').removeClass('is-fixed');
    var offsetRight =
      jQuery(window).width() -
      (jQuery('.subnav-primary.open').offset().left +
        jQuery('.subnav-primary.open').outerWidth());
    if (offsetRight < 0) {
      jQuery('.subnav-primary.open').addClass('is-fixed');
    } else {
      jQuery('.subnav-primary.open').removeClass('is-fixed');
    }
  }
});

jQuery('.nav-primary_item.has-submenu').mouseover(function () {
  var submenu = jQuery(this).find('.subnav-primary');
  submenu.removeClass('is-fixed');
  var offsetRight =
    jQuery(window).width() - (submenu.offset().left + submenu.outerWidth());
  if (offsetRight < 0) {
    submenu.addClass('is-fixed');
  } else {
    submenu.removeClass('is-fixed');
  }
});

// Primary Navigation (Mobile)
/*
document.querySelector('.nav-primary_trigger-mobile').addEventListener('click', event => {
    let body = document.querySelector('body');
    let wrapper = document.querySelector('.wrapper');
    let mainContainer = document.querySelector('.main-container');

    if(body.classList.contains('mobile_nav-main_open')) {
        let scrollCurrent = mainContainer.dataset.scrollCurrent;
        setTimeout(function() {
            mainContainer.style.marginTop = '';
            document.documentElement.scrollTop = scrollCurrent;
            body.scrollTop = scrollCurrent;
        }, 301);
    } else {
        let scrollCurrent = window.scrollY;
        mainContainer.style.marginTop = '-' + scrollCurrent + 'px';
        mainContainer.setAttribute('data-scroll-current', scrollCurrent);
    }

    body.classList.toggle('mobile_nav-main_open');

    setTimeout(function() {
        body.classList.toggle('is-sticky-header');
        wrapper.classList.toggle('fix-height');
    }, 300);
});
*/

// Video
document.querySelectorAll('.js-video_placeholder').forEach(el => {
  el.addEventListener('click', event => {
    event.preventDefault();
    event.stopPropagation();
    el.classList.add('open');
  });
});

// Progress Bar
if (
  typeof document.getElementById('progress-bar') != 'undefined' &&
  document.getElementById('progress-bar') != null
) {
  let progressBarWrapper = document.querySelector('.progress-bar_wrapper');
  let progressBarPos = progressBarWrapper.offsetTop;
  let progressBar = document.getElementById('progress-bar');

  window.addEventListener('resize', function () {
    progressBarPos = progressBarWrapper.offsetTop;
    ProgressBar();
  });

  ProgressBar();
  window.onscroll = function () {
    ProgressBar();
  };

  function ProgressBar() {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    let footerHeight = document.querySelector('.footer').offsetHeight;
    let scrolled = (winScroll / (height - footerHeight)) * 100;

    progressBar.style.width = scrolled + '%';

    let headerHeight = document.querySelector('.header').offsetHeight;

    if (window.scrollY > progressBarPos - headerHeight) {
      progressBarWrapper.classList.add('is-sticky');
      progressBarWrapper.style.top = headerHeight + 'px';
    } else {
      progressBarWrapper.classList.remove('is-sticky');
      progressBarWrapper.style.top = '';
    }
  }
}

// Sticky Elements
if (
  typeof document.querySelector('.js-sticky') != 'undefined' &&
  document.querySelector('.js-sticky') != null
) {
  document.querySelectorAll('.js-sticky').forEach(elem => {
    let elemPos = elem.offsetTop;
    let elemContent = elem.querySelector('.js-sticky_content');

    window.addEventListener('resize', function () {
      elemPos = elem.offsetTop;
      stickElem();
    });

    stickElem();
    window.onscroll = function () {
      stickElem();
    };

    function stickElem() {
      let winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      let headerHeight = document.querySelector('.header').offsetHeight;

      if (window.scrollY > elemPos) {
        elem.classList.add('is-sticky');
      } else {
        elem.classList.remove('is-sticky');
      }
    }
  });
}

// Copy Share Button
if (
  typeof document.getElementById('share-modal-button') != 'undefined' &&
  document.getElementById('share-modal-button') != null
) {
  document
    .getElementById('share-modal-button')
    .addEventListener('click', button => {
      var copyText = document.getElementById('share-modal-input');

      copyText.select();
      copyText.setSelectionRange(0, 99999);

      document.execCommand('copy');

      alert('Text copied: ' + copyText.value);
    });
}

// Copy Subscribe Button
jQuery('.js-rss-feed').on('click', function () {
  var value = jQuery(this).attr('data-feed');
  var message = jQuery(this).attr('data-message');
  var tempInput = document.createElement('input');

  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  tempInput.setSelectionRange(0, 99999);
  document.execCommand('copy');
  document.body.removeChild(tempInput);

  alert(message);
});

let slideUp = (target, duration = 300) => {
  target.classList.add('is-animating');

  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.boxSizing = 'border-box';
  target.style.height = target.offsetHeight + 'px';
  target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;

  window.setTimeout(() => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');

    target.classList.remove('is-animating');
  }, duration);
};

let slideDown = (target, duration = 300) => {
  target.classList.add('is-animating');

  target.style.removeProperty('display');
  let display = window.getComputedStyle(target).display;

  if (display === 'none') display = 'block';

  target.style.display = display;

  let height = target.offsetHeight;

  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.height = height + 'px';
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');

  window.setTimeout(() => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');

    target.classList.remove('is-animating');
  }, duration);
};

let slideToggle = (target, duration = 300) => {
  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
};

// Help Centre Categories Dropdown
if (
  typeof document.querySelector('.js-categories') != 'undefined' &&
  document.querySelector('.js-categories') != null
) {
  document.querySelector('.js-categories').addEventListener('change', elem => {
    document.querySelectorAll('.archive-group > .grid').forEach(group => {
      if (elem.target.value == 'all') {
        if (!group.parentElement.classList.contains('open')) {
          group.parentElement.classList.add('open');
          slideDown(group.parentElement);
        }
      } else if (elem.target.value == group.id) {
        if (!group.parentElement.classList.contains('open')) {
          group.parentElement.classList.add('open');
          slideDown(group.parentElement);
        }
      } else {
        group.parentElement.classList.remove('open');
        slideUp(group.parentElement);
      }
    });
  });
}

// Team Categories Dropdown
if (
  typeof document.getElementById('_team-categories-desktop') != 'undefined' &&
  document.getElementById('_team-categories-desktop') != null
) {
  let select = document.getElementById('_team-categories-desktop');

  Array.from(select.options).forEach(option => {
    if (window.location.href == option.value) select.value = option.value;
  });

  select.addEventListener('change', elem => {
    window.location.href = elem.target.value;
  });
}

/*
 * Datepickers
 */

// Air Datepicker
import datepicker from './functions/air-datepicker';

// Flatpickr
import flatpickr from 'flatpickr';

(function (window, document, undefined) {
  function InitDatePickers() {
    this.init();
  }
  InitDatePickers.prototype = {
    init: function init() {
      // Air Datepicker
      if (
        typeof document.querySelector('.filters-form_datepicker') !=
          'undefined' &&
        document.querySelector('.filters-form_datepicker') != null
      ) {
        jQuery('.filters-form_datepicker').datepicker({
          clearButton: true,
          onSelect: function (formattedDate, date, inst) {
            if (formattedDate) {
              jQuery('.filters-form_datepicker-label').html(formattedDate);
            } else {
              jQuery('.filters-form_datepicker-label').html(
                jQuery('.filters-form_datepicker-label').attr('data-label')
              );
            }

            jQuery('.filters-form_datepicker').off('change').trigger('change');
            // console.log(jQuery('.filters-form_datepicker').val());
          },
        });

        jQuery('.filters-form_datepicker').data('datepicker');

        if (jQuery('.filters-form_datepicker').val()) {
          var months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];

          var dates = jQuery('.filters-form_datepicker').val().split(' - ');

          if (dates.length == 1) {
            var date = jQuery('.filters-form_datepicker').val().split(' ');
            var year = date[1];
            var month = months.indexOf(date[0]);

            jQuery('.filters-form_datepicker')
              .data('datepicker')
              .selectDate(new Date(year, month));
          }

          if (dates.length == 2) {
            var date_start = dates[0].split(' ');
            var year_start = date_start[1];
            var month_start = months.indexOf(date_start[0]);
            var date_end = dates[1].split(' ');
            var year_end = date_end[1];
            var month_end = months.indexOf(date_end[0]);

            jQuery('.filters-form_datepicker')
              .data('datepicker')
              .selectDate([
                new Date(year_start, month_start),
                new Date(year_end, month_end),
              ]);
          }
        }
      }

      // Flatpickr
      const datepickerStandard = flatpickr('.datepicker-standard', {
        dateFormat: 'd-m-Y',
        allowInput: true,
        wrap: true,
      });

      // Flatpickr
      const datepickerDob = flatpickr('.datepicker-dob', {
        dateFormat: 'd-m-Y',
        allowInput: true,
        maxDate: 'today',
        wrap: true,
      });

      // Flatpickr
      const datepickerRange = flatpickr('.datepicker-range', {
        mode: 'range',
        minDate: 'today',
        dateFormat: 'd-m-Y',
        wrap: true,
        onClose: function (selectedDates, dateStr, instance) {
          if (selectedDates.length == 1) {
            instance.setDate([selectedDates[0], selectedDates[0]], true);
          }
        },
      });
    },
  };
  window.InitDatePickers = InitDatePickers;
})(window, document);

new InitDatePickers();

// Inject Video
if (
  typeof document.querySelector('.js-video_inject') != 'undefined' &&
  document.querySelector('.js-video_inject') != null
) {
  document.querySelectorAll('.js-video_inject').forEach(el => {
    let videoCode = el.dataset.video;
    el.addEventListener('click', elem => {
      document.getElementById('inject-video').innerHTML = videoCode;
    });
  });
  document.querySelector('.js-eject-video').addEventListener('click', elem => {
    document.getElementById('inject-video').innerHTML = '';
  });
}

// Search
/*
document.querySelector('.js-search').addEventListener('submit', event => {
    event.preventDefault();
    fetch(event.target.action, {
        method : 'POST',
        body : new URLSearchParams(new FormData(event.target))
    }).then((resp) => {
        return resp.text();
    }).then((body) => {
        document.getElementById('search-form-result').innerHTML = body;
        let searchResult = document.querySelector('.js-search_result');
        document.getElementById('search-form-result').innerHTML = '';
        document.getElementById('search-form-result').appendChild(searchResult);
        // event.target.reset();
    }).catch((error) => {
        console.log('Error: ' + error);
    });
});
*/

// Go To Top
document.querySelector('.js-goto_top').addEventListener('click', event => {
  scrollToElem(document.documentElement);
});

// Go To Elem
if (
  typeof document.querySelector('.js-goto_elem') != 'undefined' &&
  document.querySelector('.js-goto_elem') != null
) {
  document.querySelectorAll('.js-goto_elem').forEach(el => {
    el.addEventListener('click', event => {
      let elemId = el.dataset.elem;
      let headerHeight = document.querySelector('.header').offsetHeight;
      let stickyHeight = 0;

      if (
        typeof document.querySelector('.js-sticky') != 'undefined' &&
        document.querySelector('.js-sticky') != null
      )
        stickyHeight = document.querySelector('.js-sticky').offsetHeight;

      scrollToElem(
        document.getElementById(elemId),
        headerHeight + stickyHeight
      );
    });
  });
}

// Dates & Locations Tabs
if (
  typeof document.querySelector('.js-goto_elem') != 'undefined' &&
  document.querySelector('.js-goto_elem') != null
) {
  document.querySelectorAll('.archive-tabs_item').forEach(el => {
    el.addEventListener('click', event => {
      let elSiblings = elemSiblings(el);
      elSiblings.forEach(sibling => sibling.classList.remove('is-current'));
      el.classList.add('is-current');
    });
  });
}

// Typed
import Typed from 'typed.js';
if (
  typeof document.querySelector('#typed') != 'undefined' &&
  document.querySelector('#typed') != null
) {
  let typed = new Typed('#typed', {
    stringsElement: '#typed-strings',
    typeSpeed: 60,
    startDelay: 600,
    backDelay: 4800,
    fadeOut: true,
    showCursor: false,
    loop: true,
  });
}

// Newsletter Subscription
if (
  typeof document.querySelector('.js-newsletter_fill') != 'undefined' &&
  document.querySelector('.js-newsletter_fill') != null
) {
  document.querySelectorAll('.js-newsletter_fill').forEach(el => {
    el.addEventListener('click', event => {
      let email = document.getElementById('newsletter-fill').value;
      document.getElementById('newsletter_email_address').value = email;
    });
  });
}

// Google Map Panel
if (
  typeof document.querySelector('.gmap-panel_toggle') != 'undefined' &&
  document.querySelector('.gmap-panel_toggle') != null
) {
  document
    .querySelector('.gmap-panel_toggle')
    .addEventListener('click', event => {
      document.querySelector('.gmap-panel').classList.toggle('close');
    });
}

// Table Wrapper
if (
  typeof document.querySelector('.tablepress') != 'undefined' &&
  document.querySelector('.tablepress') != null
) {
  document.querySelectorAll('.tablepress').forEach(table => {
    let parent = table.parentNode;
    let wrapper = document.createElement('div');
    wrapper.classList.add('tablepress-wrapper');
    parent.insertBefore(wrapper, table);
    wrapper.appendChild(table);
  });
}

// Submenu / Filters (Mobile)
if (
  (typeof document.querySelector('.page-heading_submenu-trigger') !=
    'undefined' &&
    document.querySelector('.page-heading_submenu-trigger') != null) ||
  (typeof document.querySelector('.filters-mobile_trigger') != 'undefined' &&
    document.querySelector('.filters-mobile_trigger') != null)
) {
  document.querySelector('html').classList.add('has-submenu');
}

// Course Filters
if (
  typeof document.querySelector('.filters-form_item') != 'undefined' &&
  document.querySelector('.filters-form_item') != null
) {
  document.querySelectorAll('.filters-form_item').forEach(filter => {
    let counter = 0;
    let triger_label = filter.querySelector('.filters-form_trigger-label');
    let triger_label_multiple = triger_label.dataset.multiple;

    let checkboxes = filter.querySelectorAll('.input-checkbox');
    checkboxes.forEach(checkbox => {
      let input = checkbox.querySelector('input');

      if (input.classList.contains('input-all') == false && input.checked) {
        counter++;

        if (counter > 1) {
          triger_label.innerHTML = counter + ' ' + triger_label_multiple;
        } else {
          triger_label.innerHTML = checkbox.querySelector('label').innerHTML;
        }
      }
    });

    let inputs = filter.querySelectorAll('input[type="checkbox"]');
    inputs.forEach(input => {
      if (input.classList.contains('is-checked') == false) {
        input.addEventListener('change', event => {
          if (input.classList.contains('input-all')) {
            counter = 0;
            input.classList.add('is-checked');

            filter.querySelectorAll('input:not(.input-all)').forEach(input => {
              input.checked = false;
            });
          } else {
            filter.querySelector('input.input-all').checked = false;
            filter
              .querySelector('input.input-all')
              .classList.remove('is-checked');

            if (input.checked) {
              counter++;
            } else {
              counter--;
            }
          }

          checkboxes.forEach(checkbox => {
            let input = checkbox.querySelector('input');

            if (input.classList.contains('input-all') == false) {
              if (counter == 0) {
                filter.querySelector('input.input-all').checked = true;
                filter
                  .querySelector('input.input-all')
                  .classList.add('is-checked');
                triger_label.innerHTML = filter
                  .querySelector('input.input-all')
                  .parentElement.querySelector('label').innerHTML;
              } else if (counter == 1) {
                if (input.checked) {
                  triger_label.innerHTML =
                    checkbox.querySelector('label').innerHTML;
                }
              } else if (counter > 1) {
                triger_label.innerHTML = counter + ' ' + triger_label_multiple;
              }
            }
          });
        });
      }
    });
  });
}

// AOS.js
import AOS from 'aos';
AOS.init({
  duration: 600,
});

// Banners
if (typeof jQuery === 'function') {
  const app = JSON.parse(jQuery('meta[name=app]').attr('content'));
  const $personalisedContent = jQuery('.js-personalised-content');

  if ($personalisedContent.length) {
    jQuery
      .post(app.wp_ajax_url, {
        action: 'personalised_content',
        post_id: $personalisedContent.data('post-id'),
      })
      .done(function (response) {
        $personalisedContent.html(response.data.html);

        jQuery('.banner-band').each(function () {
          let banner = jQuery(this);
          let banner_id = banner.attr('data-id');

          if (Cookies.get('banner-' + banner_id) == null) {
            banner.removeClass('dismissed');
          }
        });

        jQuery('html').addClass('preload');

        let wrapper = jQuery('.wrapper');
        wrapper.attr('style', '');
        let wrapper_p_top = parseInt(wrapper.css('padding-top'));

        let banners = jQuery('.banners');
        let banners_h = parseInt(banners.height());

        jQuery('html').removeClass('preload');
        wrapper.css('padding-top', wrapper_p_top + banners_h);

        jQuery('body').delegate('.js-banner_dismiss', 'click', function (e) {
          let wrapper = jQuery('.wrapper');
          let wrapper_p_top = parseInt(wrapper.css('padding-top'));

          let banner = jQuery('#' + jQuery(this).attr('data-banner'));
          let banner_id = banner.attr('data-id');
          let banner_h = banner.height();

          banner.slideUp();
          wrapper.css('padding-top', wrapper_p_top - banner_h);
          Cookies.set('banner-' + banner_id, true, { expires: 7 });
        });

        jQuery(window).bind('resizeEnd', function () {
          jQuery('html').addClass('preload');

          let wrapper = jQuery('.wrapper');
          wrapper.attr('style', '');
          let wrapper_p_top = parseInt(wrapper.css('padding-top'));

          let banners = jQuery('.banners');
          let banners_h = parseInt(banners.height());

          jQuery('html').addClass('preload');
          wrapper.css('padding-top', wrapper_p_top + banners_h);
        });
      })
      .fail(function () {
        //
      })
      .always(function () {
        // ...
      });
  }

  jQuery(window).resize(function () {
    if (this.resizeTO) clearTimeout(this.resizeTO);

    this.resizeTO = setTimeout(function () {
      jQuery(this).trigger('resizeEnd');
    }, 100);
  });
}

// Focus
jQuery('body').delegate('.js-focus', 'click', function (e) {
  let focus = jQuery(this).attr('data-focus');
  setTimeout(function () {
    jQuery('#' + focus).focus();
  }, 300);
});

/*
 * Viewport
 */

// Define our viewportWidth variable
let viewportWidth;

// Set/update the viewportWidth value
let setViewportWidth = function () {
  viewportWidth = window.innerWidth || document.documentElement.clientWidth;
};

// Log the viewport width into the console
let logWidth = function () {
  if (
    typeof document.querySelector('.js-viewport_swap') != 'undefined' &&
    document.querySelector('.js-viewport_swap') != null
  ) {
    document.querySelectorAll('.js-viewport_swap').forEach(el => {
      let originalElement = el;
      let originalElementClone = el.cloneNode(true);
      let swapElement = document.getElementById(el.dataset.swap);
      let swapElementClone = swapElement.cloneNode(true);

      if (viewportWidth > 768) {
        if (originalElementClone.classList.contains('is-swapped')) {
          swapElement.parentNode.replaceChild(
            originalElementClone,
            swapElement
          );
          originalElement.parentNode.replaceChild(
            swapElementClone,
            originalElement
          );

          originalElementClone.classList.remove('is-swapped');
        }
      } else {
        if (!originalElementClone.classList.contains('is-swapped')) {
          swapElement.parentNode.replaceChild(
            originalElementClone,
            swapElement
          );
          originalElement.parentNode.replaceChild(
            swapElementClone,
            originalElement
          );

          originalElementClone.classList.add('is-swapped');
        }
      }
    });
  }
};

// Set our initial width and log it
setViewportWidth();
logWidth();

// On resize events, recalculate and log
window.addEventListener(
  'resize',
  function () {
    setViewportWidth();
    logWidth();
  },
  false
);

// Shortlist
/*
let courseShortlist = []
if(Cookies.get('course-shortlist') == null) {
    Cookies.set('course-shortlist', '', { expires: 7 });
} else {
    console.log(Cookies.get('course-shortlist'))
}
if(typeof(document.querySelector('.js-shortlist_item-add')) != 'undefined' && document.querySelector('.js-shortlist_item-add') != null) {
    document.querySelectorAll('.js-shortlist_item-add').forEach(el => {
        let courseId = el.dataset.course.replace('course-', '');
        el.addEventListener('click', event => {

            courseShortlist.push(courseId);
        });
    });
}
*/

/*
const barSticky = stickybits('.progress-bar_wrapper', {
    stickyBitStickyOffset: 145 + 650
});
*/

// Feature List
jQuery('li', '.feature-list').each(function () {
  jQuery(this).wrapInner('<div></div>');
});

// Hash scroll
window.addEventListener('load', event => {
  let hash = window.location.hash.substring(1);
  if (hash) {
    let elem = document.getElementById(hash);
    if (typeof (elem != 'undefined' && elem != null)) {
      let headerHeight = document.querySelector('.header').offsetHeight;
      let stickyHeight = 0;

      if (
        typeof document.querySelector('.js-sticky') != 'undefined' &&
        document.querySelector('.js-sticky') != null
      )
        stickyHeight = document.querySelector('.js-sticky').offsetHeight;

      scrollToElem(elem, headerHeight + stickyHeight);

      elem.classList.add('open');
      jQuery('#' + hash)
        .find('.js-accordion_container')
        .css('display', 'block');
    }
  }
});

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SUPPORT FUNCTIONS ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

const scrollToElem = (elem, offset) => {
  const y = elem.getBoundingClientRect().top + window.pageYOffset - offset;
  window.scrollTo({
    top: y,
    behavior: 'smooth',
  });
};

const elemSiblings = n => [...n.parentElement.children].filter(c => c != n);

// GA Events
import './functions/gaEvents';

// Mustache
import Mustache from 'mustache';

// @MB
Mustache.tags = ['<%', '%>'];
Mustache.escape = function (value) {
  return value;
};

(function ($) {
  // App config
  const app = JSON.parse($('meta[name=app]').attr('content'));

  // Helpers
  function app_html_find_and_replace(selector, html) {
    $(selector).each(function (i) {
      $(this).html($(html).find(selector + ':eq(' + i + ') >'));
    });
  }

  function app_ajax_reload(url, selector, callback) {
    $.ajax({
      url: url,
      success: function (html) {
        app_html_find_and_replace(selector, html);
        callback();
      },
    });
  }

  // jQuery functions
  $.fn.preAjax = function () {
    $('body').addClass('is-ajax-loading');
  };

  $.fn.postAjax = function () {
    $('body').removeClass('is-ajax-loading');
  };

  $.fn.selectFirstOption = function (force) {
    if (typeof force === 'undefined') {
      force = false;
    }

    const $optionsWithValues = $('option', this).filter(function () {
      return $(this).val();
    });

    if ($optionsWithValues.length === 1 || force) {
      $optionsWithValues.first().prop('selected', true);
    }

    return this;
  };

  $.fn.removeSelectOptions = function () {
    $('option', this)
      .filter(function () {
        return $(this).val();
      })
      .remove();
  };

  $.fn.hasData = function (key) {
    return typeof $(this).data(key) !== 'undefined';
  };

  $.fn.restoreModel = function (key, selectFirst, asArray) {
    const $this = $(this);

    if ($this.length === 0) {
      return; // We've most likely referred to an input that hasn't yet been rendered by Mustache JS.
    }

    key =
      typeof key !== 'undefined' ? key : $this.attr('name').replace('[]', '');
    selectFirst =
      typeof selectFirst !== 'undefined'
        ? selectFirst
        : $this.hasData('select-first')
        ? $this.data('select-first')
        : false;
    asArray = typeof asArray !== 'undefined' ? asArray : false;

    if (key in ca_form_model) {
      $this.val(!asArray ? ca_form_model[key] : [ca_form_model[key]]); // https://api.jquery.com/val/#val-value
      if (app.wp_debug_display) {
        console.log('Model restored: ' + key);
      }

      delete ca_form_model[key]; // Once we've restored a value lets unset it.
    } else if (selectFirst) {
      $this.selectFirstOption();
    }

    $this.trigger('change');
  };

  // AJAX animations
  $(document).ajaxStart(function () {
    $.fn.preAjax();
  });

  $(document).ajaxStop(function () {
    $.fn.postAjax();
  });

  // Shortlist management
  $(document).on(
    'click',
    '.js-shortlist_item-add, .js-shortlist_item-remove',
    function (e) {
      const $this = $(this),
        post_id = $this.data('post-id');

      let shortlist_hash = $('.js-shortlist_hash').attr('data-shortlist-hash');

      $.ajax({
        url: app.wp_ajax_url,
        data: {
          action: 'list_add',
          post_id: post_id,
        },
        success: function (response) {
          const $els = $(
            '.js-shortlist_item-add[data-post-id="' + post_id + '"]'
          );
          if (response.data.includes(post_id)) {
            // Added
            $els.addClass('is-added');
            shortlist_hash = shortlist_hash + '-' + post_id;
          } else {
            // Removed
            $els.removeClass('is-added');
            shortlist_hash = shortlist_hash.replace('-' + post_id, '');
          }

          // Hash
          $('.js-shortlist_hash').attr('data-shortlist-hash', shortlist_hash);
          document.location.hash = $('.js-shortlist_hash').attr(
            'data-shortlist-hash'
          );

          // Count
          $('.js-shortlist_counter .btn-badge').attr(
            'data-val',
            response.data.length
          );
          $('.js-shortlist_counter .btn-badge').html(response.data.length);

          // Open panel
          if ($this.hasClass('js-shortlist_item-add')) {
            $('.js-shortlist_counter').click();
          }

          // Reload the list modal content.
          app_ajax_reload(
            window.location.href,
            '.js-course-shortlist',
            function () {
              //
            }
          );
        },
      });
    }
  );

  if (document.location.hash.includes('list')) {
    $('.js-shortlist_counter').click();
  }

  $('.js-shortlist_counter').on('click', function () {
    document.location.hash = $('.js-shortlist_hash').attr(
      'data-shortlist-hash'
    );
  });
  $('.js-shortlist_close').on('click', function () {
    history.replaceState(null, null, ' ');
  });

  // Shortlist button(s)
  $('.js-course-shortlist').on(
    'click',
    '.js-shortlist-book-toggle',
    function () {
      $(this).closest('form').toggleClass('is-enabled');
    }
  );

  // Course filters
  $('.js-form-ajax').on('submit', function (e) {
    e.preventDefault();

    const $this = $(this);
    $.ajax({
      method: $this.attr('method'),
      url: $this.attr('action'),
      data: $this.serialize(),
      success: function (html) {
        app_html_find_and_replace('.archive-wrapper', html);
        history.pushState({}, null, this.url);
      },
    });
  });

  // Submit form when an input is changed.
  $('.js-input-change-submit :input').on('change', function () {
    $(this).closest('form').submit();
  });

  // Show / hide form fields based on comparisons.
  const $jsLogic = $('.js-logic'),
    jsLogicComparisons = {
      '===': function (a, b) {
        return $.inArray(a, b) !== -1;
      },
      '!==': function (a, b) {
        return $.inArray(a, b) === -1;
      },
    };

  function jsLogic() {
    $('[data-logic]', $jsLogic).each(function () {
      const $this = $(this),
        logic = $this.data('logic'),
        logicComparisons = logic.length,
        logicCallback = $this.data('logic-callback');

      let passes = 0;
      for (let i = 0; i < logicComparisons; i++) {
        const $iEl = $(logic[i].el);
        if ($iEl.length) {
          const operator =
            typeof logic[i].operator === 'undefined'
              ? '==='
              : logic[i].operator;
          if (jsLogicComparisons[operator]($iEl.val(), logic[i].values)) {
            ++passes;
          }
        }
      }

      const result = logicComparisons === passes;
      if (logicCallback && typeof window[logicCallback] === 'function') {
        window[logicCallback](result);
      } else {
        if (result) {
          $this.show();
        } else {
          $this.hide();
        }
      }
    });
  }

  $jsLogic.on('change', ':input', function () {
    jsLogic();
  });
  jsLogic();

  // "Course Application > Course Details" form w/ Mustache JS rendering.
  // References to jQuery elements BEFORE Mustache JS renders them won't behave as expected.
  const $ca_form_el = $('.js-ca-form'),
    ca_form_model = $ca_form_el.data('model');

  if ($ca_form_el.length) {
    $.ajax({
      url: $ca_form_el.data('urls')['course-types'],
      success: function (response) {
        const $course_types = $('.js-mustache-course-types');
        $course_types.html(
          Mustache.render($($course_types.data('template')).html(), {
            options: response,
          })
        );
        $(':input[name=course_type]').restoreModel();
      },
    });
  }

  $ca_form_el.on('change', '.js-onchange-1', function () {
    const $this = $(this),
      course_type_id = $this.val(),
      $courses = $('.js-mustache-courses'),
      $locations = $('.js-mustache-locations');

    if (course_type_id) {
      $.ajax({
        url: $ca_form_el
          .data('urls')
          ['course-type'].replace('{id}', course_type_id),
        success: function (response) {
          $courses.html(
            Mustache.render($($courses.data('template')).html(), {
              options: response.courses,
            })
          );
          $('[name=course]').restoreModel();
        },
      });
    } else {
      $('[name=course]').val('').trigger('change'); // Always trigger before deletion.
      $('[name=location]').val('').trigger('change');

      $courses.empty();
    }
  });

  $ca_form_el.on('change', '.js-onchange-2', function () {
    const course_id = $(this).val(),
      $locations = $('.js-mustache-locations'),
      $addons = $('.js-mustache-addons'),
      $erasmus = $('.js-mustache-erasmus'),
      $course_ai = $('.js-mustache-course-ai');

    if (course_id) {
      $.ajax({
        url: $ca_form_el.data('urls')['course'].replace('{id}', course_id),
        success: function (response) {
          // Locations
          $locations.html(
            Mustache.render($($locations.data('template')).html(), {
              options: response.locations,
            })
          );
          $('[name=location]').restoreModel();

          // Addons
          $addons.html(
            Mustache.render($($addons.data('template')).html(), {
              addons: response.addons,
            })
          );
          $('[name^=addons]').restoreModel('addons');

          // Erasmus
          $erasmus.html(
            Mustache.render($($erasmus.data('template')).html(), {
              erasmus: response.erasmus,
            })
          );
          $('[name=erasmus]').restoreModel('erasmus', false, true);

          // Additional info
          $course_ai.html(
            Mustache.render($($course_ai.data('template')).html(), {
              text: response.additional_information,
            })
          );
        },
      });
    } else {
      $('[name=location]').val('').trigger('change');

      $locations.empty();
      $addons.empty();
      $erasmus.empty();
      $course_ai.empty();
    }
  });

  $ca_form_el.on('change', '.js-onchange-3', function () {
    const location_id = $(this).val(),
      course_id = $("[name='course']").val(),
      $dates = $('.js-mustache-dates'),
      $accommodation = $('.js-mustache-accommodation');

    if (location_id && course_id) {
      $.ajax({
        url: $ca_form_el.data('urls')['course'].replace('{id}', course_id),
        data: {
          location_id: location_id,
        },
        success: function (response) {
          $dates.html(
            Mustache.render($($dates.data('template')).html(), {
              options: response.dates,
            })
          );

          if (response.dates.length > 0) {
            $('[name^=dates]').restoreModel();
          } else {
            $('[name=start_date]').restoreModel();
            $('[name=duration]').restoreModel();

            // Init datepicker.
            new InitDatePickers();

            // Filter the duration weeks based on course min/max weeks (if supplied).
            const $duration = $(':input[name="duration"]', $ca_form_el);
            $('option', $duration)
              .filter(function () {
                const val = parseInt($(this).val());

                let remove = false;
                if (
                  typeof response.min_weeks !== 'undefined' &&
                  response.min_weeks
                ) {
                  if (val < response.min_weeks) {
                    remove = true;
                  }
                }

                if (
                  typeof response.max_weeks !== 'undefined' &&
                  response.max_weeks
                ) {
                  if (val > response.max_weeks) {
                    remove = true;
                  }
                }

                return remove;
              })
              .remove();
          }

          $accommodation.html(
            Mustache.render($($accommodation.data('template')).html(), {
              accommodation: response.accommodation,
            })
          );

          jsLogic(); // Must be placed AFTER Mustache JS rendering but BEFORE we restore the model values.

          $('[name=book_accommodation]').restoreModel(
            'book_accommodation',
            false,
            true
          );
          $('[name=accommodation_1]').restoreModel();
          $('[name=accommodation_2]').restoreModel();
          $('[name=accommodation_3]').restoreModel();
        },
      });
    } else {
      $dates.empty();
      $accommodation.empty();
    }
  });

  $ca_form_el.on('change', '.js-onchange-4', function () {
    const $this = $(this),
      accommodation_id = $(this).val(),
      input_name = $this.attr('name'),
      $accommodation_addons = $(
        '.js-mustache-accommodation-addons[data-input-name="' +
          input_name +
          '"]'
      );

    if (accommodation_id) {
      $.ajax({
        url: $ca_form_el
          .data('urls')
          ['accommodations'].replace('{id}', accommodation_id),
        success: function (response) {
          $accommodation_addons.html(
            Mustache.render($($accommodation_addons.data('template')).html(), {
              input: input_name,
              addons: response.addons,
            })
          );
          $('[name^=' + input_name + '_addons]').restoreModel();
        },
      });
    } else {
      $accommodation_addons.empty();
    }
  });

  const $jsBlockGeolocate = jQuery('.js-block-geolocate');
  $jsBlockGeolocate.each(function () {
    const $this = jQuery(this);

    jQuery
      .post(app.wp_ajax_url, {
        action: 'block_geolocate',
        post_id: $this.data('post-id'),
        pb_index: $this.data('pb-index'),
      })
      .done(function (response) {
        if (response.data) {
          $this.html(response.data.html);
        }
      })
      .fail(function () {
        // ...
      })
      .always(function () {
        // ...
      });
  });
})(jQuery);
