'use strict';

export default class ModalWindow {
	constructor() {
		this.listeners();
	}

	listeners() {
		document.addEventListener('click', this.toggleModalWindow, false);
		document.addEventListener('keydown', this.keyDown);
		document.addEventListener('click', this.backdropClose, false);
	}

	keyDown(el) {
		if(27 === el.keyCode) {
			document.querySelectorAll('.js-modal_wrapper').forEach(el => {
				el.classList.contains('open');
				ModalWindow.closeModalWindow();
			});
		}
	}

	backdropClose(el) {
		document.querySelectorAll('.js-modal_wrapper').forEach(elem => {
			if(elem == el.target && elem.classList.contains('open'))
				ModalWindow.closeModalWindow();
		});
	}

	toggleModalWindow(el) {
		for (var target = el.target; target && target != this; target = target.parentNode) {
			if (target.matches('.js-modal_trigger')) {
				target.blur();

				LockScroll();

				let modalID = target.dataset.modal;
				let modal = document.getElementById(modalID);

				if(modal.classList.contains('open')) {
					modal.classList.toggle('open');
					UnlockScroll();
				} else {
					modal.classList.toggle('open');
					LockScroll();
				}

				checkModalHeight(modal);
				window.addEventListener('resize', function(){ checkModalHeight(modal) });

				break;
			}
		}
	}

	static closeModalWindow() {
		document.querySelectorAll('.js-modal_wrapper.open').forEach(el => {
			el.classList.remove('open');
			UnlockScroll();
			history.replaceState(null, null, ' ');
		});
	}
}

function checkModalHeight(modal) {
	let heightBody = window.innerHeight;
	let heightModalContent = modal.querySelector('.js-modal_content').offsetHeight;

	if (heightModalContent >= heightBody)
		modal.classList.add('fixedHeight');
	else
		modal.classList.remove('fixedHeight');
}

function LockScroll() {
    document.querySelector('html').setAttribute('data-scroll-current', window.scrollY);
	window.addEventListener('scroll', NoScroll);
}

function UnlockScroll() {
	window.removeEventListener('scroll', NoScroll);
}

function NoScroll() {
    window.scroll(0, document.querySelector('html').dataset.scrollCurrent);
}
