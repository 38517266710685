'use strict';

export default class Panel {
	constructor() {
		this.listeners();
	}

	listeners() {
		document.addEventListener('click', this.togglePanel, false);
		document.addEventListener('keydown', this.keyDown);
		document.addEventListener('click', this.backdropClose, false);
	}

	keyDown(el) {
		if(27 === el.keyCode) {
			document.querySelectorAll('.js-panel').forEach(el => {
				UnlockScroll();
				el.classList.remove('is-active');
			});
		}
	}

	backdropClose(el) {
		document.querySelectorAll('.js-panel').forEach(elem => {
			if(elem == el.target && elem.classList.contains('is-active')) {
				UnlockScroll();
				elem.classList.remove('is-active');
			}
		});
	}

	togglePanel(el) {
		for (var target = el.target; target && target != this; target = target.parentNode) {
			if (target.matches('.js-panel_trigger')) {
				target.blur();

				LockScroll();

				let panelID = target.dataset.panel;
				let panel = document.getElementById(panelID);

				if(panel.classList.contains('is-active')) {
					UnlockScroll();
					panel.classList.remove('is-active');
				} else {
					LockScroll();
					panel.classList.add('is-active');
				}

				window.addEventListener('resize', function(){ checkWindowSize(panel) });

				break;
			}
		}
	}
}

function checkWindowSize(panel) {
	let widthBody = window.innerWidth;

	if ((widthBody > 768) && panel.classList.contains('is-active')) {
		UnlockScroll();
		panel.classList.remove('is-active');
	}
}

function LockScroll() {
    document.querySelector('html').setAttribute('data-scroll-current', window.scrollY);
	window.addEventListener('scroll', NoScroll);
}

function UnlockScroll() {
	window.removeEventListener('scroll', NoScroll);
}

function NoScroll() {
    window.scroll(0, document.querySelector('html').dataset.scrollCurrent);
}
